import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { SanityLegalsPage } from "../../../graphql-types";
import { usePageContext } from "../Context/pageContext";
import * as styles from "./accordionLegalsMobile.module.scss";
import { LegalsBlockContent } from "../BlockContent/LegalsBlockContent";

interface Props {
  page: SanityLegalsPage;
  location: any;
}

export const AccordionLegalsMobile: React.FC<Props> = ({ page, location }) => {
  const { lang } = usePageContext();
  const [open, setOpen] = useState<number>(0);

  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    let index = page.content?.elemList?.findIndex(
      (e: any) => e.destination === searchParams?.get("section")
    );
    index = index !== undefined && index >= 0 ? index : 0;
    setOpen(index);
  }, [location.search]);

  return (
    <div className={styles.container}>
      {page?.content?.elemList?.map((e: any, index: number) => {
        return (
          <React.Fragment key={`legals-accordion-mobile--${index}`}>
            <div
              onClick={() => setOpen(open === index ? -1 : index)}
              className={
                open === index
                  ? styles.titleAccordion
                  : styles.titleAccordionOpen
              }
            >
              <div className={open === index ? styles.title : styles.titleOpen}>
                {e?._rawTitle?.[lang] as any}
              </div>
              <FontAwesomeIcon
                className={open === index ? styles.icon : styles.iconOpen}
                icon={open === index ? faChevronUp : faChevronDown}
              />
            </div>
            {open === index && (
              <div className={styles.panel}>
                <LegalsBlockContent blocks={e?._rawContent?.[lang] as any} />
              </div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};
