import React from "react";
import BlockContent from "@sanity/block-content-to-react";
import { SanityBlock } from "../../../graphql-types";
import * as styles from "./legalsBlockContent.module.scss";

interface Props {
  blocks: SanityBlock;
}

export const LegalsBlockContent: React.FC<Props> = ({ blocks }) => {
  return (
    <div className={styles.wrapper}>
      <BlockContent blocks={blocks} />
    </div>
  );
};
