import React, { useEffect, useState } from "react";
import { SanityLegalsPage } from "../../../graphql-types";
import { LegalsBlockContent } from "../BlockContent/LegalsBlockContent";
import { usePageContext } from "../Context/pageContext";
import * as styles from "./accordionLegalsDesktop.module.scss";

interface Props {
  page: SanityLegalsPage;
  location: any;
}

export const AccordionLegalsDesktop: React.FC<Props> = ({ page, location }) => {
  const { lang } = usePageContext();
  const [open, setOpen] = useState<number>(0);

  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    let index = page.content?.elemList?.findIndex(
      (e: any) => e.destination === searchParams?.get("section")
    );
    index = index !== undefined && index >= 0 ? index : 0;
    setOpen(index);
  }, [location.search]);

  return (
    <div className={styles.container}>
      <div className={styles.bar}>
        {page?.content?.elemList?.map((e: any, index: number) => {
          return (
            <div
              key={`legals-accordion-desktop-${index}`}
              onClick={() => setOpen(open === index ? -1 : index)}
              className={open === index ? styles.titleOpen : styles.title}
            >
              {e?._rawTitle?.[lang] as string}
            </div>
          );
        })}
      </div>
      <div className={styles.content}>
        {page?.content?.elemList?.map((e: any, index: number) => (
          <React.Fragment key={`legals-accordion-desktop--${index}`}>
            {open === index && (
              <LegalsBlockContent blocks={e?._rawContent?.[lang] as any} />
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

AccordionLegalsDesktop.defaultValue = {
  open: 0,
};
