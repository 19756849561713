import { graphql } from "gatsby";
import React from "react";
import { PageLegalsQuery, SanityLegalsPage } from "../../graphql-types";
import { AccordionLegalsDesktop } from "../components/Accordion/AccordionLegalsDesktop";
import { AccordionLegalsMobile } from "../components/Accordion/AccordionLegalsMobile";
import { usePageContext } from "../components/Context/pageContext";
import { Layout } from "../components/Layout/Layout";
import { PageTitle } from "../components/PageTitle/PageTitle";
import { SEO } from "../components/Seo";

interface Props {
  data: PageLegalsQuery;
  location: any;
}

const Legals: React.FC<Props> = ({ data, location }) => {
  const { page } = data;
  const { lang } = usePageContext();

  return (
    <Layout>
      <SEO rawMeta={page?._rawMetadata as any} />
      <PageTitle content={page?.content?._rawTitle?.[lang] as string} />
      <AccordionLegalsMobile
        page={page as SanityLegalsPage}
        location={location}
      />
      <AccordionLegalsDesktop
        page={page as SanityLegalsPage}
        location={location}
      />
    </Layout>
  );
};

export default Legals;

export const QUERY = graphql`
  query PageLegals {
    page: sanityLegalsPage {
      _rawMetadata
      content {
        _rawTitle
        elemList {
          _rawTitle
          _rawContent
          destination
        }
      }
    }
  }
`;
